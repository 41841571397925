exports.components = {
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-authentication-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/authentication.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-authentication-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-errors-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/errors.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-errors-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-idempotency-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/idempotency.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-idempotency-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-introduction-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/introduction.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-introduction-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-metadata-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/metadata.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-metadata-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-pagination-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/pagination.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-pagination-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-versioning-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/api/versioning.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-api-versioning-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-funding-instructions-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/developer-resources/test-your-integration/funding-instructions.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-funding-instructions-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-online-payments-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/developer-resources/test-your-integration/online-payments.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-online-payments-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-pay-by-cloud-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/developer-resources/test-your-integration/pay-by-cloud.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-developer-resources-test-your-integration-pay-by-cloud-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-features-and-functions-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/features-and-functions.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-features-and-functions-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-getting-started-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/getting-started.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-getting-started-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-add-custom-fields-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/add-custom-fields.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-add-custom-fields-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-merchant-platform-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/boarding/merchant-platform.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-merchant-platform-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-pricing-intent-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/boarding/pricing-intent.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-pricing-intent-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-processing-account-create-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/boarding/processing-account-create.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-processing-account-create-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-create-funding-instructions-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/create-funding-instructions.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-create-funding-instructions-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-create-funding-recipients-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/create-funding-recipients.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-create-funding-recipients-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-apple-pay-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/digital-wallets/apple-pay.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-apple-pay-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-google-pay-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/digital-wallets/google-pay.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-google-pay-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-authenticate-hosted-fields-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/hosted-fields/authenticate-hosted-fields.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-authenticate-hosted-fields-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-hosted-fields-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/hosted-fields/hosted-fields.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-hosted-fields-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-set-up-hosted-fields-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/hosted-fields/set-up-hosted-fields.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-set-up-hosted-fields-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-styling-hosted-fields-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/hosted-fields/styling-hosted-fields.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-styling-hosted-fields-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-different-device-payroc-cloud-refund-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/payroc-cloud/different-device/payroc-cloud-refund.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-different-device-payroc-cloud-refund-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-different-device-payroc-cloud-sale-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/payroc-cloud/different-device/payroc-cloud-sale.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-different-device-payroc-cloud-sale-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-same-device-payroc-cloud-refund-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/payroc-cloud/same-device/payroc-cloud-refund.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-same-device-payroc-cloud-refund-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-same-device-payroc-cloud-sale-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/payroc-cloud/same-device/payroc-cloud-sale.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-same-device-payroc-cloud-sale-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-referenced-refund-bank-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/referenced-refund-bank.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-referenced-refund-bank-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-referenced-refund-card-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/referenced-refund-card.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-referenced-refund-card-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-unreferenced-refund-bank-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/unreferenced-refund-bank.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-unreferenced-refund-bank-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-unreferenced-refund-card-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/unreferenced-refund-card.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-unreferenced-refund-card-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-void-bank-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/void-bank.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-void-bank-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-void-card-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/void-card.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-void-card-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-using-our-gateway-for-recurring-billing-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/repeat-payments/using-our-gateway-for-recurring-billing.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-using-our-gateway-for-recurring-billing-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-using-your-own-system-for-recurring-billing-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/repeat-payments/using-your-own-system-for-recurring-billing.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-using-your-own-system-for-recurring-billing-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-a-card-sale-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/run-a-card-sale.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-a-card-sale-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-a-pre-auth-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/run-a-pre-auth.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-a-pre-auth-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-sale-using-bank-details-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/run-sale-using-bank-details.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-run-sale-using-bank-details-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-events-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/events.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-events-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-hateoas-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/HATEOAS.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-hateoas-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-pricing-intents-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/pricing-intents.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-pricing-intents-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-tokenization-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/tokenization.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-tokenization-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-unit-of-measure-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/unit-of-measure.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-unit-of-measure-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-currency-conversion-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/currency-conversion.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-currency-conversion-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-dynamic-descriptors-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/dynamic-descriptors.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-dynamic-descriptors-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-interchange-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/interchange.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-interchange-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-offline-processing-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/offline-processing.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-offline-processing-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-method-verification-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/payment-method-verification.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-method-verification-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-plans-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/payment-plans.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-plans-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-process-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/payment-process.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payment-process-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payroc-cloud-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/payroc-cloud.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-payroc-cloud-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-refunds-and-reversals-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/refunds-and-reversals.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-refunds-and-reversals-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-surcharging-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/surcharging.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-surcharging-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-threedsecure-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/card-payments/threedsecure.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-card-payments-threedsecure-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-getting-started-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/getting-started.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-getting-started-mdx" */),
  "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-glossary-mdx": () => import("./../../../src/components/documentation/article-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/glossary.mdx" /* webpackChunkName: "component---src-components-documentation-article-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-glossary-mdx" */),
  "component---src-components-documentation-section-landing-page-tsx": () => import("./../../../src/components/documentation/section-landing-page.tsx" /* webpackChunkName: "component---src-components-documentation-section-landing-page-tsx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/boarding/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-boarding-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/digital-wallets/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-digital-wallets-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/hosted-fields/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-hosted-fields-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/payroc-cloud/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-payroc-cloud-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/refunds/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-refunds-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/guides/integrate/repeat-payments/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-guides-integrate-repeat-payments-index-mdx" */),
  "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-index-mdx": () => import("./../../../src/components/documentation/section-mdx-renderer.tsx?__contentFilePath=/opt/buildhome/repo/content/sections/knowledge/basic-concepts/index.mdx" /* webpackChunkName: "component---src-components-documentation-section-mdx-renderer-tsx-content-file-path-opt-buildhome-repo-content-sections-knowledge-basic-concepts-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-events-tsx": () => import("./../../../src/pages/api/events.tsx" /* webpackChunkName: "component---src-pages-api-events-tsx" */),
  "component---src-pages-api-resources-tsx": () => import("./../../../src/pages/api/resources.tsx" /* webpackChunkName: "component---src-pages-api-resources-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

